import {
  AnalyticsPeriodFilterValues,
  AnalyticsPeriodGroupByValues,
  AnalyticsStateDateFilterValue,
} from "types/analytics";
import { generateLabel } from "../../helpers";
import { dateTransform } from "helpers/dataHelpers";
import {
  DAY_MONTH_YEAR_DATE,
  MONTH_YEAR_DATE,
  QUARTER_YEAR_DATE,
  YEAR_DATE,
} from "consts";

export const createPeriods = () => {
  return Object.values(AnalyticsPeriodFilterValues)
    .filter(value => value !== AnalyticsPeriodFilterValues.Custom)
    .map(value => ({
      label: generateLabel(value),
      value,
    }));
};

export const generateCustomDateLabel = (
  dateFilter: AnalyticsStateDateFilterValue,
) => {
  let label = "Custom";

  const value = "custom";

  if (!dateFilter?.customTimePeriod) {
    return [{ label, value }];
  }

  if (
    !dateFilter.customTimePeriod?.currentStartTime ||
    !dateFilter.customTimePeriod?.prevStartTime
  ) {
    return [{ label, value }];
  }

  const groupBy = dateFilter.customTimePeriod.groupBy;

  if (groupBy === AnalyticsPeriodGroupByValues.Hour) {
    const start = dateTransform({
      date: dateFilter.customTimePeriod.currentStartTime,
      format: DAY_MONTH_YEAR_DATE,
    });
    const end = dateTransform({
      date: dateFilter.customTimePeriod.prevStartTime,
      format: DAY_MONTH_YEAR_DATE,
    });

    label = `${label} (${start} <-> ${end})`;
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Day) {
    const start = dateTransform({
      date: dateFilter.customTimePeriod.currentStartTime,
      format: MONTH_YEAR_DATE,
    });
    const end = dateTransform({
      date: dateFilter.customTimePeriod.prevStartTime,
      format: MONTH_YEAR_DATE,
    });

    label = `${label} (${start} <-> ${end})`;
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Week) {
    const start = dateTransform({
      date: dateFilter.customTimePeriod.currentStartTime,
      format: QUARTER_YEAR_DATE,
    });
    const end = dateTransform({
      date: dateFilter.customTimePeriod.prevStartTime,
      format: QUARTER_YEAR_DATE,
    });

    label = `${label} (Q${start} <-> Q${end})`;
  }

  if (groupBy === AnalyticsPeriodGroupByValues.Month) {
    const start = dateTransform({
      date: dateFilter.customTimePeriod.currentStartTime,
      format: YEAR_DATE,
    });
    const end = dateTransform({
      date: dateFilter.customTimePeriod.prevStartTime,
      format: YEAR_DATE,
    });

    label = `${label} (${start} <-> ${end})`;
  }

  return [{ label, value }];
};
