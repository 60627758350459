// Lib
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
// Api
import { useGetLocationsDetailsSummaryAnalyticsQuery } from "rtkQuery/query/analyticsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification } from "hooks";
// Actions
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Types
import { OrderStatus } from "types/orders";
// Theme
// Constants
import { ADMIN_ROUTES, queryParams } from "consts";
// Helpers
import { generateDates, getTooltip } from "pages/Loop/Analytics/helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
// Layouts
// Components
import { ArrowBackLink } from "components";
import { Filters, StatisticsSummary } from "../../../components";
import { LocationsChart } from "../../components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { PageTitleContainer, SummaryGrid } from "./styled";
import { AnalyticsStatsSummaryKey } from "types/analytics";

export const LocationAnalyticsDetails: FC = () => {
  const { id } = useParams();

  const { openNotification } = useNotification();

  const companyId = useAppSelector(getUserCompanyId);

  const analyticsFilters = useAppSelector(getAnalyticsFilters);

  const { current, prev, timezoneOffset } = generateDates(
    analyticsFilters.dateFilter,
  );

  const { data, isFetching, error } =
    useGetLocationsDetailsSummaryAnalyticsQuery(
      {
        companyId,
        locationId: id,
        query: {
          deliveredOnly: analyticsFilters.orderStatus === OrderStatus.DELIVERED,
          siteId: analyticsFilters.location,
          dateBefore: current.startTime,
          dateAfter: current.endTime,
          dateBeforePrev: prev.startTime,
          dateAfterPrev: prev.endTime,
          timezoneOffset,
        },
      },
      { ...queryParams, skip: !companyId },
    );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  return (
    <>
      <PageTitleContainer>
        <FlexContainer $column $gap={16}>
          <ArrowBackLink
            title="Back to Locations Analytics"
            path={ADMIN_ROUTES.ANALYTICS_LOCATIONS.path}
          />
          {isFetching ? (
            <Skeleton.Input />
          ) : (
            <Typography.H1>{data?.name}</Typography.H1>
          )}
        </FlexContainer>

        <Filters skipLocation />
      </PageTitleContainer>

      <SummaryGrid>
        <StatisticsSummary
          isLoading={isFetching}
          title="Total Order"
          text={data?.orders}
          tooltip={getTooltip(AnalyticsStatsSummaryKey.TotalOrders)}
        />

        <StatisticsSummary
          isLoading={isFetching}
          title="Total revenue"
          text={data?.revenue && `KWD ${data.revenue}`}
          tooltip={getTooltip(AnalyticsStatsSummaryKey.TotalRevenue)}
        />

        <StatisticsSummary
          isLoading={isFetching}
          title="Average order value"
          text={data?.avgPrice && `KWD ${data.avgPrice}`}
          tooltip={getTooltip(AnalyticsStatsSummaryKey.AverageOrderValue)}
        />

        <StatisticsSummary
          isLoading={isFetching}
          title="Best selling product"
          text={data?.bestseller}
        />
      </SummaryGrid>

      <LocationsChart isSummaryFetching={isFetching} summaryData={data} />
    </>
  );
};
