// Lib
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
// Api
import { useGetMenuItemDetailsSummaryAnalyticsQuery } from "rtkQuery/query/analyticsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification } from "hooks";
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Constants
import { ADMIN_ROUTES, queryParams } from "consts";
// Helpers
import { generateDates } from "../../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { ArrowBackLink } from "components";
import { Filters } from "../../../components";
import { DetailedSummary, ItemsChart } from "../../components";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";
import { PageTitleContainer } from "./styles";

export const MenuItemAnalyticsDetails: FC = () => {
  const { id } = useParams();

  const { openNotification } = useNotification();

  const companyId = useAppSelector(getUserCompanyId);

  const analyticsFilters = useAppSelector(getAnalyticsFilters);

  const { current, prev, timezoneOffset } = generateDates(
    analyticsFilters.dateFilter,
  );

  const {
    data: summaryData,
    isFetching: isSummaryDataFetching,
    error: summaryDataError,
  } = useGetMenuItemDetailsSummaryAnalyticsQuery(
    {
      companyId,
      itemId: id,
      query: {
        siteId: analyticsFilters.location,
        dateBefore: current.startTime,
        dateAfter: current.endTime,
        dateBeforePrev: prev.startTime,
        dateAfterPrev: prev.endTime,
        timezoneOffset,
      },
    },
    { ...queryParams, skip: !companyId },
  );

  useEffect(() => {
    if (summaryDataError) {
      errorHandler({ error: summaryDataError, openNotification });
    }
  }, [summaryDataError]);

  return (
    <>
      <PageTitleContainer>
        <FlexContainer $column $gap={16}>
          <ArrowBackLink
            title="Back to Menu Items Analytics"
            path={ADMIN_ROUTES.ANALYTICS_MENUS.path}
          />
          {isSummaryDataFetching ? (
            <Skeleton.Input />
          ) : (
            <Typography.H1>{summaryData?.name}</Typography.H1>
          )}
        </FlexContainer>

        <Filters skipOrderStatus />
      </PageTitleContainer>

      <DetailedSummary isLoading={isSummaryDataFetching} data={summaryData} />

      <ItemsChart
        isSummaryFetching={isSummaryDataFetching}
        summaryData={summaryData}
      />
    </>
  );
};
