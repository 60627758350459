// Lib
import { FC, memo } from "react";
// Types
import { ChartButtonProps } from "./types";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";

import {
  Arrow,
  Button,
  CompareSummary,
  CurrentValue,
  Info,
  PrevTitle,
  PrevValue,
  TooltipContainer,
} from "./styled";
import { Tooltip } from "components/Tooltip";

export const ChartButton: FC<ChartButtonProps> = memo(
  ({
    statsKey,
    title,
    tooltip,
    statsValue,
    statsPrevValue,
    statsValuePrefix,
    isInvertedProfit,
    profitDisplay = "percents",
    isSelected,
    onClick,
  }) => {
    const getProfit = () => {
      if (profitDisplay === "percents") {
        if (statsPrevValue === 0) {
          if (statsValue === 0) {
            return null;
          } else {
            return "100";
          }
        }

        return (((statsValue - statsPrevValue) / statsPrevValue) * 100).toFixed(
          1,
        );
      }

      return (statsValue - statsPrevValue).toFixed(3);
    };

    const profit = getProfit();

    const valueTitle = [statsValuePrefix, statsValue.toString()]
      .filter(Boolean)
      .join(" ");
    const prevValueTitle = [statsValuePrefix, statsPrevValue.toString()]
      .filter(Boolean)
      .join(" ");

    const isPositiveResult = isInvertedProfit
      ? statsValue <= statsPrevValue
      : statsValue > statsPrevValue;

    return (
      <Button $active={isSelected} onClick={() => onClick(statsKey)}>
        <Typography.TitleThin>{title}</Typography.TitleThin>

        <FlexContainer $gap={8}>
          <CurrentValue>{valueTitle}</CurrentValue>

          {!!profit && statsValue !== statsPrevValue && (
            <CompareSummary $isPositiveResult={isPositiveResult}>
              <Arrow $isPositiveResult={isPositiveResult} />

              <span>
                {profit}
                {profitDisplay === "percents" ? " %" : ""}
              </span>
            </CompareSummary>
          )}
        </FlexContainer>

        <div>
          <PrevTitle as="span">Previous period</PrevTitle>
          <PrevValue as="span">{prevValueTitle}</PrevValue>
        </div>

        {tooltip && (
          <TooltipContainer>
            <Tooltip title={tooltip}>
              <span>
                <Info />
              </span>
            </Tooltip>
          </TooltipContainer>
        )}
      </Button>
    );
  },
);

ChartButton.displayName = "ChartButton";
