// Lib
import { FC, memo, useState } from "react";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useViewport } from "hooks";
// Actions
import {
  setAnalyticsDateFilter,
  setAnalyticsLocationFilter,
  setAnalyticsOrderStatusFilter,
} from "rtkQuery/slices";
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Types
import { AnalyticsPeriodFilterValues } from "types/analytics";
// Helpers
import { generateLabel } from "../../helpers";
// Components
import { Select } from "components/Form";
import { CustomDateModal } from "../CustomDateModal";
// Styled
import { FlexContainer } from "styled/Box";
import { OrderStatus, OrderStatusLabel } from "types/orders";
import { createPeriods, generateCustomDateLabel } from "./helpers";

interface FilterProps {
  skipLocation?: boolean;
  skipOrderStatus?: boolean;
}

export const Filters: FC<FilterProps> = memo(
  ({ skipLocation, skipOrderStatus }) => {
    const { isLargeMobile } = useViewport();
    const dispatch = useAppDispatch();

    const companyId = useAppSelector(getUserCompanyId);

    const { location, orderStatus, dateFilter } =
      useAppSelector(getAnalyticsFilters);

    const [customPeriodModal, setCustomPeriodModal] = useState<boolean>(false);

    const { data } = useGetLocationsQuery(
      { companyId },
      { refetchOnMountOrArgChange: true },
    );

    const onChangeDates = (value: AnalyticsPeriodFilterValues) => {
      if (value === AnalyticsPeriodFilterValues.Custom) {
        return;
      }

      dispatch(setAnalyticsDateFilter({ value }));
    };

    const onChangeLocation = (id: string) => {
      dispatch(setAnalyticsLocationFilter(id));
    };

    const onChangeOrderStatus = (status: OrderStatus | "") => {
      dispatch(setAnalyticsOrderStatusFilter(status));
    };

    const onSelect = (value: AnalyticsPeriodFilterValues) => {
      if (value !== AnalyticsPeriodFilterValues.Custom) {
        return;
      }

      setCustomPeriodModal(true);
    };

    const locations =
      data?.map(({ name, id }) => ({ label: name, value: id })) || [];

    const periods = createPeriods();

    const customPeriod = generateCustomDateLabel(dateFilter);

    const orderStatusOptions = [
      {
        value: "",
        label: "All Orders",
      },
      {
        value: OrderStatus.DELIVERED,
        label: OrderStatusLabel[OrderStatus.DELIVERED],
      },
    ];

    const isLocationsSelectHidden = skipLocation || locations?.length < 2;

    return (
      <>
        <FlexContainer
          $gap={10}
          $wrap
          $fullwidth={isLargeMobile}
          $column={isLargeMobile}
          $align={isLargeMobile ? "stretch" : "flex-start"}
        >
          {!skipOrderStatus && (
            <Select
              style={{ width: !isLargeMobile && 200 }}
              value={orderStatus}
              onChange={onChangeOrderStatus}
              options={orderStatusOptions}
            />
          )}

          {!isLocationsSelectHidden && (
            <Select
              style={{ width: !isLargeMobile && 200 }}
              value={location}
              onChange={onChangeLocation}
              options={[
                {
                  value: "",
                  label: "All Locations",
                },
                ...locations,
              ]}
            />
          )}

          <Select
            style={{ minWidth: !isLargeMobile && 290 }}
            value={dateFilter.value}
            onChange={onChangeDates}
            onSelect={onSelect}
            options={[...periods, ...customPeriod]}
          />
        </FlexContainer>

        <CustomDateModal
          open={customPeriodModal}
          onClose={() => setCustomPeriodModal(false)}
        />
      </>
    );
  },
);

Filters.displayName = "Filters";
