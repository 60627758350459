import { Permission } from "consts/permissions";
// Types
import { SidebarMenuItemsType } from "types/common";
// Icons
import {
  AnalyticIcon,
  GroupIcon,
  InventoryIcon,
  LocationsIcon,
  MenuIcon,
  OperationsIcon,
  OrdersIcon,
  PauseCircleIcon,
  PersonIcon,
  PosIcon,
  ProductIcon,
  SettingsIcon,
} from "icons";

export const sidebarMenuItems: SidebarMenuItemsType = {
  loop: [
    {
      key: "orders",
      Icon: OrdersIcon,
      label: "Orders",
      path: "/loop/orders",
      type: "link",
      access: true,
    },
    {
      key: "customers",
      Icon: PersonIcon,
      label: "Customers",
      path: "/loop/customers",
      type: "link",
      access: true,
    },
    {
      key: "operations",
      Icon: OperationsIcon,
      label: "Operations",
      type: "list",
      list: [
        {
          key: "operations_report",
          label: "Operations report",
          path: "/loop/operations/operations_report",
          access: [Permission.ListOperationLogs],
        },
        {
          key: "snoozing",
          label: "Snoozing",
          path: "/loop/operations/snoozing",
          access: true,
        },
      ],
    },
    {
      key: "menus",
      Icon: MenuIcon,
      label: "Menus",
      type: "list",
      list: [
        {
          key: "all-menus",
          label: "All Menus",
          path: "/loop/menus/all-menus",
          access: [Permission.ListMenus],
        },
        {
          key: "scheduling",
          label: "Schedule",
          path: "/loop/menus/schedule",
          access: [Permission.ListMenus],
        },
      ],
    },
    {
      key: "inventory",
      Icon: InventoryIcon,
      label: "Inventory",
      path: "/loop/inventory",
      type: "link",
      access: true,
    },
    {
      key: "products",
      Icon: ProductIcon,
      label: "Products",
      type: "list",
      access: [Permission.ListProducts],
      list: [
        {
          key: "all-products",
          label: "All Products",
          path: "/loop/products/all-products",
          access: [Permission.ListProducts],
        },
        {
          key: "addons-modifiers",
          label: "Modifiers",
          path: "/loop/products/addons-modifiers",
          access: [Permission.ListProducts],
        },
        {
          key: "tags",
          label: "Tags",
          path: "/loop/products/tags",
          access: [Permission.ListTags],
        },
        {
          key: "labels",
          label: "Labels",
          path: "/loop/products/labels",
          access: [Permission.ListTags],
        },
      ],
    },
    {
      key: "analytics",
      Icon: AnalyticIcon,
      label: "Analytics",
      type: "list",
      access: [Permission.ReadAnalytic],
      list: [
        {
          key: "orders-analytics",
          label: "Orders",
          path: "/loop/analytics/orders-analytics",
          access: [Permission.ReadAnalytic],
        },
        {
          key: "menus-analytics",
          label: "Menu Items",
          path: "/loop/analytics/menus-analytics",
          access: [Permission.ReadAnalytic],
        },
        {
          key: "locations-analytics",
          label: "Locations",
          path: "/loop/analytics/locations-analytics",
          access: [Permission.ReadAnalytic],
        },
      ],
    },
    {
      key: "location",
      Icon: LocationsIcon,
      label: "Location",
      path: "/loop/location",
      type: "link",
      access: [Permission.ListSites],
    },
    {
      key: "pos-employees",
      Icon: PosIcon,
      label: "POS Employees",
      path: "/loop/pos-employees",
      type: "link",
      access: [Permission.ListSimphonyEmployee],
    },
    {
      key: "users",
      Icon: GroupIcon,
      label: "Users",
      path: "/loop/users",
      type: "link",
      access: [Permission.ListUsers],
    },
    {
      key: "settings",
      Icon: SettingsIcon,
      label: "Settings",
      path: "/loop/settings",
      type: "link",
      access: true,
    },
  ],
  kitchenPwa: [
    {
      key: "orders",
      Icon: OrdersIcon,
      label: "Orders",
      path: "/orders",
      type: "link",
      access: true,
    },
    {
      key: "busy-mode",
      Icon: PauseCircleIcon,
      label: "Busy mode",
      type: "link",
      path: "/busy-mode",
      access: true,
    },
    {
      key: "inventory",
      Icon: InventoryIcon,
      label: "Inventory",
      path: "/inventory",
      type: "link",
      access: [Permission.ReadInventory],
    },
    {
      key: "settings",
      Icon: SettingsIcon,
      label: "Settings",
      type: "link",
      path: "/settings",
      access: true,
    },
  ],
};
