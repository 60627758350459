import { FilterStateItem } from "types/tableFilters";
import { ModifierType, UrlQueryParams } from "./common";

export enum OrderStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  PREPARING = "preparing",
  PREPARED = "prepared",
  READY_FOR_PICKUP = "ready_for_pickup",
  IN_DELIVERY = "in_delivery",
  DELIVERED = "delivered",
  AUTO_FINALIZED = "auto_finalized",
  CANCELED = "canceled",
  SCHEDULED = "scheduled",
  FAILED = "failed",
}

export enum OrderStatusLabel {
  pending = "New",
  accepted = "Accepted",
  preparing = "Preparing",
  prepared = "Prepared",
  ready_for_pickup = "Ready for pickup",
  in_delivery = "In delivery",
  delivered = "Delivered",
  auto_finalized = "Auto finalized",
  canceled = "Canceled",
  scheduled = "Scheduled",
}

export enum OrderService {
  SWISH = "swish",
  TALABAT = "talabat",
}

export enum OrderServiceLabel {
  swish = "Swish",
  talabat = "Talabat",
}

export interface OrderFiltersState {
  filters: FilterStateItem[] | [];
}

export enum EOrderTypeValue {
  PICK_UP = "1",
  DELIVERY = "2",
  EAT_IN = "3",
}

export enum EOrderTypeLabel {
  PICK_UP = "Pick Up",
  DELIVERY = "Delivery",
  EAT_IN = "Eat In",
}

export enum EPaymentMethod {
  CARD = "Card",
  CASH = "Cash",
  FIAT = "fiatCents",
  FMC = "fmcCents",
}

export interface OrderStatusHistoryItem {
  previousStatus: string | null;
  newStatus: "pending" | string;
  timestamp: string;
}

export interface Order {
  id: string;
  sid: string;
  menuId: string;
  locationId: string;
  locationName: string;
  deliveryId: string;
  customerId: string;
  name: string;
  active: boolean;
  items: OrderItem[] | [];
  totalFiatCentsPrice: number;
  totalFmcCentsPrice: number;
  orderStatus: OrderStatus;
  chargeStatus: string;
  chargeFailureReason: string | null;
  orderStatusHistory: OrderStatusHistoryItem[];

  chargeStatusHistory: OrderStatusHistoryItem[];
  createdAt: string;
  updatedAt: string;
}

export interface OrderParams {
  offset?: number;
  limit?: number;
  sortBy?: string;
  sortingOrder?: string;
}

export enum OrderFilterTypes {
  pickupTime = "pickupTime",
  location = "sites",
  orderId = "sid",
  orderStatus = "statuses",
  orderType = "types",
  service = "service",
}

export enum OrderFilterNames {
  pickupTime = "Pickup Time",
  sites = "Location",
  sid = "Order ID",
  statuses = "Order status",
  types = "Order type",
  service = "Service",
}

export interface OrderItem {
  id: string;
  productName: string;
  productPlu: string;
  quantity: number;
  fmcCentsPrice: string;
  fiatCentsPrice: string;
  remark: string;
  updatedAt: string;
  modifiers: {
    id: string;
    productPlu: string;
    fcmCentsPrice: string;
    fiatCentsPrice: string;
    quantity?: number;
    product: {
      id: string;
      name: string;
      name2: string;
      imageUrl: null | string;
      externalFiatPrice: null | string;
      modifierType: ModifierType;
    };
  }[];
}

export interface OrderHistories {
  id: string;
  status: OrderStatus;
  createdAt: string;
  updatedAt: string;
}
export interface OrderDetailsResponse {
  id: string;
  status: OrderStatus;
  deliverySystem: string;

  chargeStatus: string;
  orderIsAlreadyPaid: boolean;
  chargeFailureReason?: string;
  sid: string;

  pickupTime?: Date;
  deliveryAddress: string;
  courierInfo: string;
  customerName: string;
  phoneNumber: string;
  email: string;
  note: string;
  totalFmcCentsPrice: number;
  totalFiatCentsPrice: number;
  companyId: string;
  orderItems: OrderItem[];
  orderHistories: OrderHistories[];
  createdAt: string;
  paymentMethod: EPaymentMethod;

  discountTotal: string;
  discountFiatTotal: string;
  discountFmcTotal: string;
  deliveryCost: string;
  deliveryFeeFiatCents: string;
  deliveryFeeFmcCents: string;
}

export interface Order {
  pickupTime: Date;
  sid: string;
  customerName: string;
  phoneNumber: string;
  site: { id: string; name: string };
  orderChannel: string;
  status: OrderStatus;
  deliverySystem: string;
  createdAt: string;
  updatedAt: string;
}

export interface GetOrdersResponse {
  data: Order[];
  page: number;
  size: number;
  total: number;
  totalPages: number;
}

// const fields = [
//   'pickupTime',
//   'sid',
//   'customerName',
//   'phoneNumber',
//   'siteName',
//   'status',
// ] as const;

export interface GetOrdersRequest {
  // orderBy: string;
  // search: string;
  // name: string;

  // sites: string[];
  // types: EOrderTypeValues[];
  // statuses: OrderStatus[];
  // pickupTimeBefore: string;
  // pickupTimeAfter: string;
  companyId: string;
  query: UrlQueryParams;
}
