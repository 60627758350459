// Lib
import { FC, Fragment, memo, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
// Api
import { useGetMenuItemDetailsChartAnalyticsQuery } from "rtkQuery/query/analyticsAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification } from "hooks";
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Types
import {
  AnalyticsStatsSummaryKey,
  GetMenuItemDetailsSummaryAnalyticsResponse,
} from "types/analytics";
// Constants
import { queryParams } from "consts";
// Helpers
import {
  createButtons,
  createChartConfig,
  createChartData,
  generateDates,
} from "../../../helpers";
import { createItemsChartTabs } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Spin } from "antd";
import { Loader } from "components";
import { ChartButton, LineChart } from "components/Chart";
// Styled
import { ButtonsWrapper, Devider, Wrapper } from "./styled";

interface ItemsChartProps {
  isSummaryFetching: boolean;
  summaryData: GetMenuItemDetailsSummaryAnalyticsResponse;
}

export const ItemsChart: FC<ItemsChartProps> = memo(
  ({ isSummaryFetching, summaryData }) => {
    const { id } = useParams();

    const { openNotification } = useNotification();

    const companyId = useAppSelector(getUserCompanyId);
    const analyticsFilters = useAppSelector(getAnalyticsFilters);

    const [selected, setSelected] = useState<AnalyticsStatsSummaryKey | null>(
      null,
    );

    const { current, prev, timezoneOffset, groupBy } = generateDates(
      analyticsFilters.dateFilter,
    );

    const { data: locationsData } = useGetLocationsQuery({ companyId });

    const {
      data: chartData,
      isFetching: isChartFetching,
      error: chartError,
    } = useGetMenuItemDetailsChartAnalyticsQuery(
      {
        companyId,
        itemId: id,
        query: {
          siteId: analyticsFilters.location,
          dateBefore: current.startTime,
          dateAfter: current.endTime,
          dateBeforePrev: prev.startTime,
          dateAfterPrev: prev.endTime,
          compare: analyticsFilters.comparing,
          period: analyticsFilters.dateFilter.value,
          timezoneOffset,
          groupBy,
        },
      },
      { ...queryParams, skip: !companyId },
    );

    useEffect(() => {
      if (chartError) {
        errorHandler({ error: chartError, openNotification });
      }
    }, [chartError]);

    const tabs = useMemo(
      () => createItemsChartTabs(summaryData),
      [summaryData],
    );

    const statsButtons = useMemo(() => createButtons(tabs), [tabs]);

    useEffect(() => {
      if (!!tabs?.length && !selected) {
        setSelected(tabs[0]?.dataKey);
      }
    }, [tabs]);

    const locations = useMemo(
      () =>
        locationsData?.map(({ name, id }) => ({ label: name, value: id })) ||
        [],
      [locationsData],
    );

    const chartValuesData = useMemo(
      () =>
        createChartData(chartData?.data?.slotsData || [], locations, selected),
      [chartData?.data?.slotsData, locations, selected],
    );

    const { legendConfig, linesConfig } = useMemo(
      () =>
        createChartConfig({
          locations,
          activeLocationId: analyticsFilters.location,
          analyticsFilters,
        }),
      [analyticsFilters.location, locations, chartData?.data?.slotsData],
    );

    const isLocationsCompare = locations?.length > 1;

    const isLoading = isSummaryFetching || isChartFetching;

    return (
      <>
        <Spin
          spinning={isLoading}
          indicator={
            <>
              <Loader />
            </>
          }
        >
          <Wrapper>
            <ButtonsWrapper>
              {statsButtons?.map((button, i, array) => (
                <Fragment key={button.statsKey}>
                  <ChartButton
                    statsKey={button.statsKey}
                    title={button.title}
                    tooltip={button?.tooltip}
                    profitDisplay={button.profitDisplay}
                    statsValue={button.statsValue}
                    statsPrevValue={button.statsPrevValue}
                    statsValuePrefix={button.statsValuePrefix}
                    isSelected={button.statsKey === selected}
                    isInvertedProfit={button.isInvertedProfit}
                    onClick={setSelected}
                  />
                  {i !== array?.length - 1 && <Devider className="devider" />}
                </Fragment>
              ))}
            </ButtonsWrapper>

            <LineChart
              isLocationsCompare={isLocationsCompare}
              legendConfig={legendConfig}
              linesConfig={linesConfig}
              chartData={chartValuesData}
            />
          </Wrapper>
        </Spin>
      </>
    );
  },
);

ItemsChart.displayName = "ItemsChart";
