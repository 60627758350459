// Lib
import { FC } from "react";
import { Skeleton } from "antd";
// Styled
import { Typography } from "styled/Typography";
import { Info, TooltipContainer, Wrapper } from "./styled";
import { Tooltip } from "components";

interface StatisticsSummaryProps {
  isLoading?: boolean;
  title: string;
  text: string | number;
  tooltip?: string;
}

export const StatisticsSummary: FC<StatisticsSummaryProps> = ({
  isLoading,
  title,
  text,
  tooltip,
}) => {
  return (
    <Wrapper>
      <Typography.TitleThin>{title}</Typography.TitleThin>
      {isLoading ? (
        <Skeleton.Input active size="large" />
      ) : (
        <Typography.H2>{text}</Typography.H2>
      )}

      {tooltip && (
        <TooltipContainer>
          <Tooltip title={tooltip}>
            <span>
              <Info />
            </span>
          </Tooltip>
        </TooltipContainer>
      )}
    </Wrapper>
  );
};
