import { AccentText } from "components/Table/styled";

export const columns = [
  {
    title: "Location",
    dataIndex: "siteName",
    key: "siteName",
    render: (text: string) => <AccentText>{text}</AccentText>,
  },
  {
    title: "Order",
    dataIndex: "orders",
    key: "orders",
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue",
    render: (text: string) => `KWD ${text}`,
  },
  {
    title: "AVG Order value",
    dataIndex: "avgPrice",
    key: "avgPrice",
    render: (text: string) => `KWD ${text}`,
  },
];
