// Lib
import { FC, memo } from "react";
import {
  LineChart as RechartLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartTooltip,
  ResponsiveContainer,
} from "recharts";
import { useDispatch } from "react-redux";
// Api
// Hooks
import { useAppSelector } from "hooks/redux";
// Actions
import { setAnalyticsComparingFilter } from "rtkQuery/slices";
// Selectors
import { getAnalyticsComparingFilters } from "rtkQuery/selectors";
// Types
import {
  ChartCompareLabels,
  ChartCompareValues,
  ChartData,
} from "types/analytics";
import { ChartLegendConfig, ChartLinesConfig } from "./types";
// Components
import { Select } from "components/Form";
import { ChartTooltip } from "../ChartTooltip";
import { Legend } from "../Legend";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { ChartWrapper, Wrapper } from "./styled";

interface LineChartProps {
  legendConfig: ChartLegendConfig[];
  linesConfig: ChartLinesConfig[];
  chartData: ChartData[];
  isLocationsCompare: boolean;
}

export const LineChart: FC<LineChartProps> = memo(
  ({ legendConfig, linesConfig, chartData, isLocationsCompare }) => {
    const dispatch = useDispatch();

    const compare = useAppSelector(getAnalyticsComparingFilters);

    const onCompareSelect = (value: ChartCompareValues) => {
      dispatch(setAnalyticsComparingFilter(value));
    };

    const options = [
      {
        value: ChartCompareValues.PreviousPeriod,
        label: ChartCompareLabels[ChartCompareValues.PreviousPeriod],
      },
      {
        value: ChartCompareValues.SelectedLocation,
        label: ChartCompareLabels[ChartCompareValues.SelectedLocation],
        disabled: !isLocationsCompare,
      },
    ];

    return (
      <Wrapper>
        <FlexContainer
          $align="center"
          $justify="space-between"
          $margin="0 0 24px 0"
          $wrap
          $gap={10}
        >
          <Typography.H3>Total Order</Typography.H3>

          <FlexContainer $align="center" $width="257px" $gap={8}>
            <Typography.TitleThin>Compare</Typography.TitleThin>

            <div style={{ minWidth: 180 }}>
              <Select
                options={options}
                value={compare}
                onChange={onCompareSelect}
              />
            </div>
          </FlexContainer>
        </FlexContainer>

        <Legend legendConfig={legendConfig} />

        <ChartWrapper>
          <ResponsiveContainer width="100%" height="100%">
            <RechartLineChart width={500} height={340} data={chartData}>
              <CartesianGrid vertical={false} stroke="rgba(235, 235, 235, 1)" />

              <XAxis axisLine={false} tickLine={false} dataKey="name" />

              <YAxis axisLine={false} tickLine={false} />

              <RechartTooltip content={ChartTooltip} />

              {!!linesConfig?.length &&
                linesConfig.map(line => (
                  <Line
                    key={line.dataKey}
                    type="monotone"
                    strokeWidth={2}
                    dot={false}
                    {...line}
                  />
                ))}
            </RechartLineChart>
          </ResponsiveContainer>
        </ChartWrapper>
      </Wrapper>
    );
  },
);

LineChart.displayName = "LineChart";
