import { InfoIcon } from "icons";
import styled from "styled-components";
import { ContentBox, FlexContainer } from "styled/Box";

export const Wrapper = styled(ContentBox)`
  min-height: 120px;
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;

export const TooltipContainer = styled(FlexContainer)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const Info = styled(InfoIcon)`
  width: 18px;
  height: 18px;
`;
