import { DatePicker as AntDDatePicker } from "antd";
import styled from "styled-components";
import { InputStyles, hoverErrorStyles } from "../styled";
import { theme } from "theme";
import { InfoIcon } from "icons";

export const StyledDatePicker = styled(AntDDatePicker)<{ status: string }>`
  width: 100%;

  ${InputStyles}

  ${({ status }) => status === "error" && hoverErrorStyles}


  svg {
    path {
      fill: ${theme.color.text.tertiary};
    }
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const Info = styled(InfoIcon)`
  cursor: pointer;

  width: 18px;
  height: 18px;
  margin-bottom: -4px;
  margin-left: 4px;
`;
