// Lib
import { FC } from "react";
// Types
import { MenuFormTypes } from "types/menus";
import { MenuTypesOptions } from "types/common";
import { Location } from "types/locations";
// Components
import { DatePicker, Input, Select } from "components/Form";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import {
  createDateStringFromTime,
  createTimeString,
} from "helpers/dataHelpers";

interface MenuFormProps {
  isEdit: boolean;
  values: MenuFormTypes;
  locationsData: Location[];
  handleFormFieldChange: (args: MenuFormTypes) => void;
}

export const MenuForm: FC<MenuFormProps> = ({
  isEdit,
  locationsData,
  values,
  handleFormFieldChange,
}) => {
  const handleFieldChange = (value: string, field: keyof MenuFormTypes) => {
    handleFormFieldChange({ ...values, [field]: value });
  };

  const locations =
    locationsData?.length &&
    locationsData.map(el => ({ label: el.name, value: el.id }));

  const menuTypes = Object.keys(MenuTypesOptions).map(key => ({
    label: key,
    value: MenuTypesOptions[key as keyof typeof MenuTypesOptions],
  }));

  const workStartTime = createDateStringFromTime(values.workStartTime);
  const workEndTime = createDateStringFromTime(values.workEndTime);

  return (
    <FlexContainer $column $gap={16}>
      <ContentBox $column>
        <InputsGridContainer>
          <Input
            required
            label="Menu name"
            value={values?.name}
            onChange={e => handleFieldChange(e.target.value, "name")}
          />

          <Input
            label="Internal menu name"
            value={values.internalName}
            onChange={e => handleFieldChange(e.target.value, "internalName")}
          />

          <Select
            label="Type"
            value={values.internalType}
            onChange={value => handleFieldChange(value, "internalType")}
            options={menuTypes}
          />

          <Select
            required
            disabled={isEdit}
            label="Location"
            value={values.location}
            onChange={value => handleFieldChange(value, "location")}
            options={locations}
          />

          <DatePicker
            required
            label="Work start time"
            labelTooltip="The field is required for scheduling orders. To allow users to pre-order an item, its work time must align with the selected time slot."
            picker="time"
            format={"HH:mm"}
            value={workStartTime}
            onChange={value =>
              handleFieldChange(createTimeString(value), "workStartTime")
            }
          />

          <DatePicker
            required
            label="Work end time"
            labelTooltip="The field is required for scheduling orders. To allow users to pre-order an item, its work time must align with the selected time slot."
            picker="time"
            format={"HH:mm"}
            value={workEndTime}
            onChange={value =>
              handleFieldChange(createTimeString(value), "workEndTime")
            }
          />
        </InputsGridContainer>
      </ContentBox>
    </FlexContainer>
  );
};
