import { getUserPermissions } from "rtkQuery/selectors";
import { useAppSelector } from "./redux";
import { Permission, initialPermissions } from "consts";

export const usePermissions = () => {
  const userPermissions = useAppSelector(getUserPermissions);

  const userCan: typeof initialPermissions = userPermissions.reduce(
    (
      acc: Record<keyof typeof initialPermissions, boolean>,
      el: string,
    ): Record<keyof typeof initialPermissions, boolean> => {
      switch (el) {
        case Permission.ListCompanies:
          acc.canListCompanies = true;
          break;
        case Permission.ReadCompanyInfo:
          acc.canReadCompanyInfo = true;
          break;
        case Permission.CreateCompany:
          acc.canCreateCompany = true;
          break;
        case Permission.UpdateCompanyInfo:
          acc.canUpdateCompanyInfo = true;
          break;
        case Permission.DeactivateCompany:
          acc.canDeactivateCompany = true;
          break;

        case Permission.ListSites:
          acc.canListSites = true;
          break;
        case Permission.ReadSite:
          acc.canReadSite = true;
          break;
        case Permission.CreateSite:
          acc.canCreateSite = true;
          break;
        case Permission.UpdateSite:
          acc.canUpdateSite = true;
          break;
        case Permission.DeactivateSite:
          acc.canDeactivateSite = true;
          break;

        case Permission.ListUsers:
          acc.canListUsers = true;
          break;
        case Permission.CreateUser:
          acc.canCreateUser = true;
          break;
        case Permission.UpdateUser:
          acc.canUpdateUser = true;
          break;
        case Permission.DeactivateUser:
          acc.canDeactivateUser = true;
          break;

        case Permission.ListMenus:
          acc.canListMenus = true;
          break;
        case Permission.ReadMenu:
          acc.canReadMenu = true;
          break;
        case Permission.CreateMenu:
          acc.canCreateMenu = true;
          break;
        case Permission.UpdateMenu:
          acc.canUpdateMenu = true;
          break;
        case Permission.DeleteMenu:
          acc.canDeleteMenu = true;
          break;

        case Permission.ListProducts:
          acc.canListProducts = true;
          break;
        case Permission.ReadProduct:
          acc.canReadProduct = true;
          break;
        case Permission.CreateProduct:
          acc.canCreateProduct = true;
          break;
        case Permission.UpdateProduct:
          acc.canUpdateProduct = true;
          break;
        case Permission.DeleteProduct:
          acc.canDeleteProduct = true;
          break;

        case Permission.ListOperationLogs:
          acc.canListOperationReports = true;
          break;
        case Permission.ReadOperationLog:
          acc.canReadOperationReport = true;
          break;

        case Permission.SnoozeMenuItems:
          acc.canSnoozeMenuItems = true;
          break;

        case Permission.ListTags:
          acc.canListTags = true;
          break;
        case Permission.ReadTag:
          acc.canReadTag = true;
          break;
        case Permission.CreateTag:
          acc.canCreateTag = true;
          break;
        case Permission.UpdateTag:
          acc.canUpdateTag = true;
          break;
        case Permission.DeleteTag:
          acc.canDeleteTag = true;
          break;

        case Permission.ListOrders:
          acc.canListOrders = true;
          break;
        case Permission.ReadOrder:
          acc.canReadOrder = true;
          break;
        case Permission.UpdateOrder:
          acc.canUpdateOrder = true;
          break;

        case Permission.ListSimphonyEmployee:
          acc.canListSimphonyEmployee = true;
          break;
        case Permission.UpdateSimphonyActive:
          acc.canUpdateSimphonyActive = true;
          break;

        case Permission.ReadSettings:
          acc.canReadSettings = true;
          break;
        case Permission.UpdateSettings:
          acc.canUpdateSettings = true;
          break;

        case Permission.ListChannels:
          acc.canListChannels = true;
          break;
        case Permission.UpdateChannel:
          acc.canUpdateChannel = true;
          break;

        case Permission.ReadInventory:
          acc.canReadInventory = true;
          break;
        case Permission.UpdateInventory:
          acc.canUpdateInventory = true;
          break;

        case Permission.ReadAnalytic:
          acc.canReadAnalytic = true;
          break;
      }

      return acc;
    },
    initialPermissions,
  );

  return userCan;
};
