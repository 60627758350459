import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const analyticsUrlBuilder = {
  getOrdersSummaryAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/orders/general`,
      query,
    );
  },
  getOrdersChartAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/orders/chart`,
      query,
    );
  },
  getOrderRevenueBreakdownAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/orders/revenue-breakdown`,
      query,
    );
  },
  getOrderHourlyDistributionAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/orders/hourly-distribution`,
      query,
    );
  },
  getMenuItemsSummaryAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/menu-items/general`,
      query,
    );
  },
  getMenuListSummaryAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/menu-items/list`,
      query,
    );
  },
  getMenuItemDetailsSummaryAnalytics: ({
    companyId,
    itemId,
    query,
  }: {
    companyId: string;
    itemId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/menu-items/${itemId}/general`,
      query,
    );
  },
  getMenuItemDetailsChartAnalytics: ({
    companyId,
    itemId,
    query,
  }: {
    companyId: string;
    itemId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/menu-items/${itemId}/chart`,
      query,
    );
  },
  getLocationsListAnalytics: ({
    companyId,
    query,
  }: {
    companyId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/sites/list`,
      query,
    );
  },
  getLocationsDetailsSummaryAnalytics: ({
    companyId,
    locationId,
    query,
  }: {
    companyId: string;
    locationId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/sites/${locationId}/general`,
      query,
    );
  },
  getLocationsDetailsChartAnalytics: ({
    companyId,
    locationId,
    query,
  }: {
    companyId: string;
    locationId: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(
      `${endpoints.companies}${companyId}/analytics/sites/${locationId}/chart`,
      query,
    );
  },
};
