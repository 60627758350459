// Lib
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useGetLocationsListAnalyticsQuery } from "rtkQuery/query/analyticsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useExport, useNotification, useTable, useViewport } from "hooks";
// Actions
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
// Theme
import { theme } from "theme";
// Constants
import { queryParams } from "consts";
// Helpers
import { generateDates } from "../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { FileUploadIcon } from "icons";
// Layouts
// Components
import { Export, Pagination, Table } from "components";
import { Filters } from "../../components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  PageTitleContainer,
  PageWrapper,
  TableShadowContainer,
} from "./styled";

import { columns } from "./config";

export const AnalyticsLocations: FC = () => {
  const navigate = useNavigate();
  const { isDesktop } = useViewport();
  const { downloadCSV } = useExport();
  const companyId = useAppSelector(getUserCompanyId);

  const { openNotification } = useNotification();

  const analyticsFilters = useAppSelector(getAnalyticsFilters);

  const { current, timezoneOffset } = generateDates(
    analyticsFilters.dateFilter,
  );

  const {
    pageSize,
    setPageSize,
    orderBy,
    orderDirection,
    currentPage,
    setCurrentPage,
  } = useTable({ name: ETable.AnalyticsLocations });

  const { data, isLoading } = useGetLocationsListAnalyticsQuery(
    {
      companyId,
      query: {
        siteId: analyticsFilters.location,
        dateBefore: current.startTime,
        dateAfter: current.endTime,
        timezoneOffset,
        page: currentPage,
        size: pageSize,
        orderBy,
        orderDirection,
      },
    },
    { ...queryParams, skip: !companyId },
  );

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    try {
      if (!data) {
        return;
      }

      return data.data as unknown as Record<string, unknown>[];
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const onRow = useCallback(record => {
    return {
      onClick: () => {
        navigate(`/loop/analytics/locations-analytics/${record?.siteId}`);
      },
    };
  }, []);

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer $fullwidth $column $grow={1} $padding="0 0 32px">
        <PageTitleContainer>
          <Typography.H1>Locations Analytics</Typography.H1>

          <Filters skipLocation skipOrderStatus />
        </PageTitleContainer>

        <TableShadowContainer>
          <Table
            isLoading={isLoading}
            shadow={false}
            withPagination={true}
            dataSource={data?.data || []}
            columns={columns}
            header={{
              leftTitle: "Summary",
              suffix: isDesktop ? (
                <Export
                  type="Base"
                  fileName="locations"
                  isLoading={false}
                  isDataLoading={false}
                  isDisabled={false}
                  columns={columns}
                  getExportData={getExportData}
                />
              ) : (
                <Button.SquaredIcon
                  icon={<FileUploadIcon fill={theme.color.text.tertiary} />}
                  onClick={() =>
                    downloadCSV({
                      fileName: "product_sales",
                      isDataLoading: false,
                      columns: columns,
                      getExportData: getExportData,
                    })
                  }
                />
              ),
            }}
            onRow={onRow}
          />

          <Pagination
            padding="12px 16px"
            pageSize={pageSize}
            currentPage={currentPage}
            setPageSize={setPageSize}
            totalItems={data?.total}
            setCurrentPage={setCurrentPage}
          />
        </TableShadowContainer>
      </FlexContainer>
    </PageWrapper>
  );
};
