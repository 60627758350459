// Lib
import { FC, memo } from "react";
// Types
import { GetMenuItemsSummaryAnalyticsResponse } from "types/analytics";
// Components
import { StatisticsSummary } from "../../../components";
// Styled
import { StaticsGrid } from "./styled";

interface MainSummaryProps {
  isLoading: boolean;
  data: GetMenuItemsSummaryAnalyticsResponse;
}

export const MainSummary: FC<MainSummaryProps> = memo(({ isLoading, data }) => {
  return (
    <StaticsGrid>
      <StatisticsSummary
        isLoading={isLoading}
        title="Canceled Order"
        text={data?.totalCanceledOrders}
      />

      <StatisticsSummary
        isLoading={isLoading}
        title="Total snoozed operations"
        text={data?.totalSnoozedCount}
      />

      <StatisticsSummary
        isLoading={isLoading}
        title="Total time with at least 1 snoozed item"
        tooltip="This metric is calculated as the cumulative timeline of all snoozed menu items within the selected time range. It represents the longest overlapping snooze durations"
        text={data?.totalSnoozedTime}
      />
    </StaticsGrid>
  );
});

MainSummary.displayName = "MainSummary";
