import { Avatar } from "antd";
import { Badge } from "components";

import { AccentText } from "components/Table/styled";
import { MONTH_DAY_YEAR_HOURS_MINUTES_DATE } from "consts";
import { convertStatusToText, dateTransform } from "helpers/dataHelpers";
import { LocationsIcon } from "icons";
import {
  OrderFilterNames,
  OrderFilterTypes,
  OrderStatus,
  OrderStatusLabel,
  OrderService,
  OrderServiceLabel,
} from "types/orders";
import {
  FilterField,
  FilterOption,
  FilterFieldDropdownOption,
} from "types/tableFilters";

import { EFilterDateLabel, EFilterDateValue } from "types/common";

export const orderServiceDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: OrderService.SWISH,
    label: OrderServiceLabel[OrderService.SWISH],
  },
  {
    type: OrderService.TALABAT,
    label: OrderServiceLabel[OrderService.TALABAT],
  },
];

export const orderStatusDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: OrderStatus.PENDING,
    label: OrderStatusLabel[OrderStatus.PENDING],
  },
  {
    type: OrderStatus.ACCEPTED,
    label: OrderStatusLabel[OrderStatus.ACCEPTED],
  },
  {
    type: OrderStatus.PREPARING,
    label: OrderStatusLabel[OrderStatus.PREPARING],
  },
  {
    type: OrderStatus.PREPARED,
    label: OrderStatusLabel[OrderStatus.PREPARED],
  },
  {
    type: OrderStatus.READY_FOR_PICKUP,
    label: OrderStatusLabel[OrderStatus.READY_FOR_PICKUP],
  },
  {
    type: OrderStatus.AUTO_FINALIZED,
    label: OrderStatusLabel[OrderStatus.AUTO_FINALIZED],
  },
  {
    type: OrderStatus.CANCELED,
    label: OrderStatusLabel[OrderStatus.CANCELED],
  },
  {
    type: OrderStatus.IN_DELIVERY,
    label: OrderStatusLabel[OrderStatus.IN_DELIVERY],
  },
  {
    type: OrderStatus.DELIVERED,
    label: OrderStatusLabel[OrderStatus.DELIVERED],
  },
];

export const dateTimeDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: EFilterDateValue.TODAY,
    label: EFilterDateLabel[EFilterDateValue.TODAY],
  },
  {
    type: EFilterDateValue.YESTERDAY,
    label: EFilterDateLabel[EFilterDateValue.YESTERDAY],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_7_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_7_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_30_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_30_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_90_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_90_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_12_MONTHS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_12_MONTHS],
  },
  {
    type: EFilterDateValue.CUSTOM,
    label: EFilterDateLabel[EFilterDateValue.CUSTOM],
  },
];

export const columns = [
  {
    title: "Pickup time",
    dataIndex: "pickupTime",
    key: "pickupTime",
    render: date => (
      <AccentText>
        {dateTransform({
          date,
          format: MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
        })}
      </AccentText>
    ),
    sorter: true,
  },
  {
    title: "Order ID",
    dataIndex: "sid",
    key: "sid",
    sorter: true,
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
    render: text => (
      <>
        <Avatar
          size={28}
          style={{ backgroundColor: "#FA8545", marginRight: 8 }}
        >
          {text && Array.from(text as string)[0]}
        </Avatar>
        {text}
      </>
    ),
    sorter: true,
  },
  {
    title: "Mobile number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    sorter: true,
  },
  {
    title: "Location",
    dataIndex: "siteName",
    key: "site",
    render: (_text, record) => record?.site?.name,
    sorter: true,
  },
  {
    title: "Channel",
    dataIndex: "channel",
    key: "channel",
  },
  {
    title: "Service",
    dataIndex: "service",
    key: "service",
    render: (text: OrderService) => OrderServiceLabel[text],
  },
  {
    title: "Delivery system",
    dataIndex: "deliverySystem",
    key: "deliverySystem",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <Badge status={text} title={text && convertStatusToText(text)} />
    ),
    sorter: true,
  },
];

export const filtersList: FilterOption[] = [
  {
    key: OrderFilterTypes.pickupTime,
    label: OrderFilterNames[OrderFilterTypes.pickupTime],
    field: FilterField.DATE_RANGE,
    options: dateTimeDropdownFields,
  },
  {
    key: OrderFilterTypes.location,
    icon: <LocationsIcon />,
    label: OrderFilterNames[OrderFilterTypes.location],
    field: FilterField.MULTISELECT,
    options: [],
  },
  {
    key: OrderFilterTypes.orderId,
    label: OrderFilterNames[OrderFilterTypes.orderId],
    field: FilterField.INPUT,
  },
  {
    key: OrderFilterTypes.service,
    label: OrderFilterNames[OrderFilterTypes.service],
    field: FilterField.SELECT,
    options: orderServiceDropdownFields,
  },
  {
    key: OrderFilterTypes.orderStatus,
    label: OrderFilterNames[OrderFilterTypes.orderStatus],
    field: FilterField.MULTISELECT,
    options: orderStatusDropdownFields,
  },
];
