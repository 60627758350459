// Lib
import { FC, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
// Hooks
import { useAppDispatch, useAppSelector } from "hooks/redux";
// Actions
import { setAnalyticsDateFilter } from "rtkQuery/slices";
// Selectors
import { getAnalyticsFilters } from "rtkQuery/selectors";
// Types
import {
  AnalyticsPeriodFilterValues,
  AnalyticsPeriodGroupByLabels,
  AnalyticsPeriodGroupByValues,
} from "types/analytics";
// Components
import { Modal } from "components/Modal";
import { DatePicker, Select } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

interface CustomDateModalProps {
  open: boolean;
  onClose: () => void;
}

export const CustomDateModal: FC<CustomDateModalProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { dateFilter } = useAppSelector(getAnalyticsFilters);

  const [dates, setDates] = useState<[Dayjs, Dayjs] | null>(null);
  const [prevDates, setPrevDates] = useState<[Dayjs, Dayjs] | null>(null);
  const [groupBy, setGroupBy] = useState<AnalyticsPeriodGroupByValues>(
    AnalyticsPeriodGroupByValues.Hour,
  );

  useEffect(() => {
    if (!dateFilter?.customTimePeriod) {
      setDates(null);
      setPrevDates(null);
      setGroupBy(AnalyticsPeriodGroupByValues.Hour);
      return;
    }

    const {
      customTimePeriod: {
        currentStartTime,
        currentEndTime,
        prevStartTime,
        prevEndTime,
        groupBy,
      },
    } = dateFilter;

    const dates: [Dayjs, Dayjs] = [
      dayjs(currentStartTime),
      dayjs(currentEndTime),
    ];

    const prevDates: [Dayjs, Dayjs] = [
      dayjs(prevStartTime),
      dayjs(prevEndTime),
    ];

    setDates(dates);
    setPrevDates(prevDates);
    setGroupBy(groupBy);
  }, [open]);

  const onAccept = () => {
    dispatch(
      setAnalyticsDateFilter({
        value: AnalyticsPeriodFilterValues.Custom,
        customTimePeriod: {
          currentStartTime: dayjs(dates[0])?.startOf("day")?.toISOString(),
          currentEndTime: dayjs(dates[1])?.endOf("day")?.toISOString(),
          prevStartTime: dayjs(prevDates[0])?.startOf("day")?.toISOString(),
          prevEndTime: dayjs(prevDates[1])?.endOf("day")?.toISOString(),
          groupBy,
        },
      }),
    );

    onClose();
  };

  const handleSetGroupByValue = (value: AnalyticsPeriodGroupByValues) => {
    setDates(null);
    setPrevDates(null);
    setGroupBy(value);
  };

  const groupByOptions = Object.values(AnalyticsPeriodGroupByValues).map(
    value => ({
      label: AnalyticsPeriodGroupByLabels[value],
      value,
    }),
  );

  const isDisabled =
    !dates ||
    !prevDates ||
    !dates.length ||
    !prevDates.length ||
    !dates[0] ||
    !prevDates[0];

  return (
    <Modal
      title="Change custom period"
      width={400}
      open={open}
      onClose={onClose}
    >
      <FlexContainer $fullwidth $column $gap={16}>
        <Select
          label="Group by"
          options={groupByOptions}
          value={groupBy}
          onChange={handleSetGroupByValue}
        />

        {groupBy === AnalyticsPeriodGroupByValues.Hour && (
          <>
            <DatePicker
              allowClear
              label="Date"
              format={"DD/MM/YYYY"}
              value={dates ? dates[0] : null}
              onChange={v => setDates([v, v])}
            />

            <DatePicker
              allowClear
              label="Previous date"
              format={"DD/MM/YYYY"}
              value={prevDates ? prevDates[0] : null}
              onChange={v => setPrevDates([v, v])}
            />
          </>
        )}

        {groupBy === AnalyticsPeriodGroupByValues.Day && (
          <>
            <DatePicker
              allowClear
              label="Month"
              picker="month"
              format={"MM/YYYY"}
              value={dates ? dates[0] : null}
              onChange={v => setDates([v?.startOf("month"), v?.endOf("month")])}
            />

            <DatePicker
              allowClear
              label="Previous month"
              picker="month"
              format={"MM/YYYY"}
              value={prevDates ? prevDates[0] : null}
              onChange={v =>
                setPrevDates([v?.startOf("month"), v?.endOf("month")])
              }
            />
          </>
        )}

        {groupBy === AnalyticsPeriodGroupByValues.Week && (
          <>
            <DatePicker
              allowClear
              label="Quarter"
              picker="quarter"
              format={"Q/YYYY"}
              value={dates ? dates[0] : null}
              onChange={v =>
                setDates([v?.startOf("quarter"), v?.endOf("quarter")])
              }
            />

            <DatePicker
              allowClear
              label="Previous quarter"
              picker="quarter"
              format={"Q/YYYY"}
              value={prevDates ? prevDates[0] : null}
              onChange={v =>
                setPrevDates([v?.startOf("quarter"), v?.endOf("quarter")])
              }
            />
          </>
        )}
        {groupBy === AnalyticsPeriodGroupByValues.Month && (
          <>
            <DatePicker
              allowClear
              label="Year"
              picker="year"
              format={"YYYY"}
              value={dates ? dates[0] : null}
              onChange={v => setDates([v?.startOf("year"), v?.endOf("year")])}
            />

            <DatePicker
              allowClear
              label="Previous year"
              picker="year"
              format={"YYYY"}
              value={prevDates ? prevDates[0] : null}
              onChange={v =>
                setPrevDates([v?.startOf("year"), v?.endOf("year")])
              }
            />
          </>
        )}
      </FlexContainer>

      <FlexContainer
        $fullwidth
        $align="center"
        $justify="flex-end"
        $gap={8}
        $margin="24px 0 0"
      >
        <Button.Heading onClick={onClose}>Close</Button.Heading>

        <Button.Heading type="primary" disabled={isDisabled} onClick={onAccept}>
          Accept
        </Button.Heading>
      </FlexContainer>
    </Modal>
  );
};
