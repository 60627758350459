import {
  AnalyticsStatsSummaryKey,
  GetLocationsDetailsSummaryAnalyticsResponse,
} from "types/analytics";

export const createLocationsChartTabs = (
  data: GetLocationsDetailsSummaryAnalyticsResponse,
) => {
  if (!data) {
    return [];
  }

  return [
    {
      dataKey: AnalyticsStatsSummaryKey.Revenue,
      value: Number(data.current.revenue),
      prevValue: Number(data.prev.revenue),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.Orders,
      value: Number(data.current.orders),
      prevValue: Number(data.prev.orders),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.AverageOrderValue,
      value: Number(data.current.averageOrderValue),
      prevValue: Number(data.prev.averageOrderValue),
    },
  ];
};
