export enum Permission {
  ListCompanies = "3d47473c-9c0c-4c1d-84de-36b3892b99f8",
  ReadCompanyInfo = "32225982-b3f8-4ca8-a0a8-1d5dd0b009e5",
  CreateCompany = "f9d0976f-18af-48a9-986f-d0b0a3126270",
  UpdateCompanyInfo = "2b13cdcd-7077-4309-ba59-7a6ccdb1f214",
  DeactivateCompany = "ee540e50-fd7d-4626-8c0a-0454eb19d56c",

  ListSites = "4feff595-633a-495d-9048-79331a4dd353",
  ReadSite = "19721026-124f-4ee9-ac14-fe63a223aa99",
  CreateSite = "997e7f07-2983-4d8f-bbaf-8213b6093235",
  UpdateSite = "4824fdd3-2bab-4beb-af4d-bdf3b8e2a68b",
  DeactivateSite = "ccb99887-3bf1-4826-a76a-5a699e036387",

  ListUsers = "8a232722-343d-4448-aa20-1f7bb9452c2a",
  CreateUser = "d0e58227-3f81-4743-90cd-3a674b24ae84",
  UpdateUser = "e5b77a54-ba4a-4bf0-a6f6-90cb8622a6bd",
  DeactivateUser = "5dfbc749-0af6-49a9-af4e-ac8388ea1a88",

  ListMenus = "a53d6d7c-628d-4f10-8436-34d42a47e8e9",
  ReadMenu = "a01f8c4c-b3e9-7a4b-9f74-019998b7351a",
  CreateMenu = "c1d5705b-0625-7e4a-5c84-196365933205",
  UpdateMenu = "5bd4275d-9a7e-64c6-a84a-b97adc10538f",
  DeleteMenu = "fd5e3c5a-ceea-5735-bc77-15c4bd97859c",

  ListProducts = "85763f6e-fe91-4b36-b872-c87401c6548f",
  ReadProduct = "f98f7c4c-b8e9-4a4b-8f74-089998b735ea",
  CreateProduct = "f2d4705b-0625-4e4a-8c84-296365933502",
  UpdateProduct = "4cd3275d-9a7e-44c6-b84a-a97adca0538f",
  DeleteProduct = "ed4e3c5a-ceea-4735-bc77-05c4bd97859c",

  SnoozeMenuItems = "a24816b1-4e0c-4228-9a58-3011b2029e19",

  ListOrders = "f73dbd04-91ce-455d-9578-bc6146687173",
  ReadOrder = "3febe017-e482-49b7-8e3a-23f4bcedb41e",
  UpdateOrder = "b8870585-05c6-48fe-b929-d8f86974445c",

  ReadUser = "d5f236bf-8171-473c-ae78-4eecd4623c6d",

  ListTags = "65ac9c58-185d-4628-991d-90bec054f4ac",
  ReadTag = "4f5de765-1912-4cc7-acf0-6dbbf81bfba0",
  CreateTag = "c5f7543f-a4f9-4744-b1c0-118e1203854b",
  UpdateTag = "7ca82199-37e6-41b0-bc50-677722bd24f6",
  DeleteTag = "dc207f8b-ad6a-4498-94a6-b211243f815e",

  ListRoles = "70eb7a9a-ac6e-49e4-83a5-3398f022b445",
  ListSimphonyEmployee = "d1bd17a4-c01a-48ad-9fc2-5832a09282fe",
  UpdateSimphonyActive = "b8633254-701b-4970-932b-50cc09f6b720",

  ListOperationLogs = "a23d951c-7773-40ba-a390-32b451586770",
  ReadOperationLog = "49596429-0b7a-4d0c-bc57-b414946a0d80",

  ReadSettings = "a1464cb3-eff4-4a7b-a7ab-300cbd99c074",
  UpdateSettings = "8f74fbce-d40d-4dbf-8091-fcf689af2d6e",

  ListChannels = "639dc374-530a-4a0a-9227-d8b8a8709996",
  UpdateChannel = "03fa47cf-d8c3-4d14-a09e-b58ce7e8b540",

  ReadInventory = "b844994e-c568-4eb3-8342-d1ca92eaa53c",
  UpdateInventory = "93084fd5-8be1-4cfb-b7b3-42a0b1d4f780",

  ReadAnalytic = "a064b75c-21d1-4513-89ec-1033f71484f4",
}

export const permissionLabel = {
  [Permission.ListCompanies]: "Companies list",
  [Permission.ReadCompanyInfo]: "Check company",
  [Permission.CreateCompany]: "Create company",
  [Permission.UpdateCompanyInfo]: "Update company",
  [Permission.DeactivateCompany]: "Deactivate company",

  [Permission.ListSites]: "Locations list",
  [Permission.ReadSite]: "Check location info",
  [Permission.CreateSite]: "Create location",
  [Permission.UpdateSite]: "Update location",
  [Permission.DeactivateSite]: "Deactivate location",

  [Permission.ListUsers]: "Users list",
  [Permission.ReadUser]: "Check user info",
  [Permission.CreateUser]: "Create user",
  [Permission.UpdateUser]: "Update user",
  [Permission.DeactivateUser]: "Deactivate user",

  [Permission.ListMenus]: "Menus list",
  [Permission.ReadMenu]: "Check menu",
  [Permission.CreateMenu]: "Create menu",
  [Permission.UpdateMenu]: "Update menu",
  [Permission.DeleteMenu]: "Delete menu",

  [Permission.ListProducts]: "Products list",
  [Permission.ReadProduct]: "Check product",
  [Permission.CreateProduct]: "Create product",
  [Permission.UpdateProduct]: "Update product",
  [Permission.DeleteProduct]: "Delete product",

  [Permission.SnoozeMenuItems]: "Snooze menu items",

  [Permission.ListOrders]: "Orders list",
  [Permission.ReadOrder]: "Check order info",
  [Permission.UpdateOrder]: "Update order",

  [Permission.ListOperationLogs]: "Operation reports list",
  [Permission.ReadOperationLog]: "Check operation report info",

  [Permission.ListTags]: "Tags list",
  [Permission.ReadTag]: "Check tag info",
  [Permission.CreateTag]: "Create tag",
  [Permission.UpdateTag]: "Update tag",
  [Permission.DeleteTag]: "Delete Tag",

  [Permission.ListRoles]: "Roles list",

  [Permission.ListSimphonyEmployee]: "Symphony employees list",
  [Permission.UpdateSimphonyActive]: "Update symphony employee",

  [Permission.ReadSettings]: "Check settings",
  [Permission.UpdateSettings]: "Update settings",

  [Permission.ListChannels]: "Channels list",
  [Permission.UpdateChannel]: "Update channel",

  [Permission.ReadInventory]: "Check inventory",
  [Permission.UpdateInventory]: "Update inventory",

  [Permission.ReadAnalytic]: "Check analytic",
};

export const initialPermissions = {
  canListCompanies: false,
  canReadCompanyInfo: false,
  canCreateCompany: false,
  canUpdateCompanyInfo: false,
  canDeactivateCompany: false,

  canListSites: false,
  canReadSite: false,
  canCreateSite: false,
  canUpdateSite: false,
  canDeactivateSite: false,

  canListUsers: false,
  canCreateUser: false,
  canUpdateUser: false,
  canDeactivateUser: false,

  canListMenus: false,
  canReadMenu: false,
  canCreateMenu: false,
  canUpdateMenu: false,
  canDeleteMenu: false,

  canListProducts: false,
  canReadProduct: false,
  canCreateProduct: false,
  canUpdateProduct: false,
  canDeleteProduct: false,

  canSnoozeMenuItems: false,

  canListTags: false,
  canReadTag: false,
  canCreateTag: false,
  canUpdateTag: false,
  canDeleteTag: false,

  canListOrders: false,
  canReadOrder: false,
  canUpdateOrder: false,

  canListOperationReports: false,
  canReadOperationReport: false,

  canListSimphonyEmployee: false,
  canUpdateSimphonyActive: false,

  canReadSettings: false,
  canUpdateSettings: false,

  canListChannels: false,
  canUpdateChannel: false,

  canReadInventory: false,
  canUpdateInventory: false,

  canReadAnalytic: false,
};
