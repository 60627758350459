// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  GetOrderRevenueBreakdownAnalyticsRequest,
  GetOrdersSummaryAnalyticsRequest,
  GetOrdersSummaryAnalyticsResponse,
  GetOrderRevenueBreakdownAnalyticsResponse,
  GetOrderHourlyDistributionAnalyticsRequest,
  GetOrderHourlyDistributionAnalyticsResponse,
  GetOrdersChartAnalyticsRequest,
  GetOrdersChartAnalyticsResponse,
  GetMenuItemsSummaryAnalyticsResponse,
  GetMenuItemsSummaryAnalyticsRequest,
  GetMenuItemsListAnalyticsRequest,
  GetMenuItemsListAnalyticsResponse,
  GetMenuItemDetailsSummaryAnalyticsRequest,
  GetMenuItemDetailsSummaryAnalyticsResponse,
  GetMenuItemDetailsChartAnalyticsRequest,
  GetMenuItemDetailsChartAnalyticsResponse,
  GetLocationsListAnalyticsResponse,
  GetLocationsListAnalyticsRequest,
  GetLocationsDetailsSummaryAnalyticsRequest,
  GetLocationsDetailsSummaryAnalyticsResponse,
  GetLocationsDetailsChartAnalyticsRequest,
  GetLocationsDetailsChartAnalyticsResponse,
} from "types/analytics";
// Helpers
import { analyticsUrlBuilder } from "./urlBuilder/analytics";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";

export const analyticsAPI = createApi({
  reducerPath: "analyticsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: [
    "summary-orders",
    "chart-orders",
    "breakdown-orders",
    "hourlyDistribution-orders",
    "summary-menu-items",
    "locations-list",
    "summary-locations",
    "chart-locations",
  ],
  endpoints: build => ({
    getOrdersSummaryAnalytics: build.query<
      GetOrdersSummaryAnalyticsResponse,
      GetOrdersSummaryAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getOrdersSummaryAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["summary-orders"],
    }),
    getOrdersChartAnalytics: build.query<
      GetOrdersChartAnalyticsResponse,
      GetOrdersChartAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getOrdersChartAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["chart-orders"],
    }),
    getOrderRevenueBreakdownAnalytics: build.query<
      GetOrderRevenueBreakdownAnalyticsResponse,
      GetOrderRevenueBreakdownAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getOrderRevenueBreakdownAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["breakdown-orders"],
    }),
    getOrderHourlyDistributionAnalytics: build.query<
      GetOrderHourlyDistributionAnalyticsResponse,
      GetOrderHourlyDistributionAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getOrderHourlyDistributionAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["hourlyDistribution-orders"],
    }),
    getMenuItemsSummaryAnalytics: build.query<
      GetMenuItemsSummaryAnalyticsResponse,
      GetMenuItemsSummaryAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getMenuItemsSummaryAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["summary-menu-items"],
    }),
    getMenuItemsListAnalytics: build.query<
      GetMenuItemsListAnalyticsResponse,
      GetMenuItemsListAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getMenuListSummaryAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["summary-menu-items"],
    }),
    getMenuItemDetailsSummaryAnalytics: build.query<
      GetMenuItemDetailsSummaryAnalyticsResponse,
      GetMenuItemDetailsSummaryAnalyticsRequest
    >({
      query: ({ companyId, itemId, query }) => {
        return {
          url: analyticsUrlBuilder.getMenuItemDetailsSummaryAnalytics({
            companyId,
            itemId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["summary-menu-items"],
    }),
    getMenuItemDetailsChartAnalytics: build.query<
      GetMenuItemDetailsChartAnalyticsResponse,
      GetMenuItemDetailsChartAnalyticsRequest
    >({
      query: ({ companyId, itemId, query }) => {
        return {
          url: analyticsUrlBuilder.getMenuItemDetailsChartAnalytics({
            companyId,
            itemId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["chart-orders"],
    }),
    getLocationsListAnalytics: build.query<
      GetLocationsListAnalyticsResponse,
      GetLocationsListAnalyticsRequest
    >({
      query: ({ companyId, query }) => {
        return {
          url: analyticsUrlBuilder.getLocationsListAnalytics({
            companyId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["locations-list"],
    }),
    getLocationsDetailsSummaryAnalytics: build.query<
      GetLocationsDetailsSummaryAnalyticsResponse,
      GetLocationsDetailsSummaryAnalyticsRequest
    >({
      query: ({ companyId, locationId, query }) => {
        return {
          url: analyticsUrlBuilder.getLocationsDetailsSummaryAnalytics({
            companyId,
            locationId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["summary-locations"],
    }),
    getLocationsDetailsChartAnalytics: build.query<
      GetLocationsDetailsChartAnalyticsResponse,
      GetLocationsDetailsChartAnalyticsRequest
    >({
      query: ({ companyId, locationId, query }) => {
        return {
          url: analyticsUrlBuilder.getLocationsDetailsChartAnalytics({
            companyId,
            locationId,
            query,
          }),
          method: "GET",
        };
      },
      providesTags: ["chart-locations"],
    }),
  }),
});

export const {
  useGetOrdersSummaryAnalyticsQuery,
  useGetOrdersChartAnalyticsQuery,
  useGetOrderRevenueBreakdownAnalyticsQuery,
  useGetOrderHourlyDistributionAnalyticsQuery,
  useGetMenuItemsSummaryAnalyticsQuery,
  useGetMenuItemsListAnalyticsQuery,
  useGetMenuItemDetailsSummaryAnalyticsQuery,
  useGetMenuItemDetailsChartAnalyticsQuery,
  useGetLocationsListAnalyticsQuery,
  useGetLocationsDetailsSummaryAnalyticsQuery,
  useGetLocationsDetailsChartAnalyticsQuery,
} = analyticsAPI;
